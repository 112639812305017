import React, { useState, useEffect, useRef, useCallback } from 'react';
import api from '../../../api';
import './EventManagement.css';
import { v4 as uuidv4 } from 'uuid';
import LoadingBar from '../../Ui/LoadingBar/LoadingBar';
import { processMultipleImages } from '../../../utils/imageprocessing';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';
import DOMPurify from 'dompurify';
import EventFinancials from './EventFinancials';


const customSchema = {
  tagNames: ['h1', 'h2', 'h3', 'p', 'strong', 'em', 'a', 'ul', 'ol', 'li', 'hr'],
  attributes: {
    a: ['href'],
    '*': ['className']
  },
  allowComments: false,
  allowElements: ['h1', 'h2', 'h3', 'p', 'strong', 'em', 'a', 'ul', 'ol', 'li', 'hr'],
  protocols: {
    href: ['http', 'https', 'mailto']
  },
  transformUrls: {
    // This function will be called for each URL
    '*': (url) => {
      // You can implement additional URL sanitization here
      return url;
    }
  }
};


/**
 * Formats a date-time string for input fields.
 *
 * @param {string} dateTimeString - The date-time string to format.
 * @returns {string} Formatted date-time string.
 */
const formatDateTimeForInput = (dateTimeString) => {
  const date = new Date(dateTimeString);
  return date.toISOString().slice(0, 16); // This will format the date as "yyyy-MM-ddThh:mm"
};

/**
 * Manages event creation, editing, and referral code generation.
 *
 * @component
 * @returns {React.ReactElement} Event management interface.
 */
const EventManagement = () => {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: '',
    description: '',
    shortDescription: '',
    dateTime: '',
    location: '',
    vendor: '',
    ticketTypes: [{ title: '', description: '', badge: '', price: '', quantity: 0, reservedTickets: 0 }],
    priceRange: '',
    lastEntry: '',
    ageRestriction: '',
    isFeatured: false,
    ticketSalesCutoff: '',
  });
  const [editingEvent, setEditingEvent] = useState(null);
  const [eventImages, setEventImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const fileInputRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ticketCounts, setTicketCounts] = useState({});
  const [showFinancials, setShowFinancials] = useState({});

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    return () => {
      previewImages.forEach(URL.revokeObjectURL);
    };
  }, [previewImages]);

  const fetchEvents = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.getAllEvents();

      // Sanitize the description field
      response.data.forEach(event => {
        event.description = DOMPurify.sanitize(event.description);
      });
      
      console.log('Fetched events:', response.data);
      setEvents(Array.isArray(response.data) ? response.data : []);
      
      // Fetch ticket counts for each event
      response.data.forEach(event => {
        fetchTicketCount(event._id);
      });
    } catch (error) {
      console.error('Error fetching events:', error);
      setEvents([]);
      setError('Failed to fetch events. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTicketCount = async (eventId) => {
    try {
      const response = await api.getEventTicketCount(eventId);
      setTicketCounts(prevCounts => ({
        ...prevCounts,
        [eventId]: response.data
      }));
    } catch (error) {
      console.error('Error fetching ticket count:', error);
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('ticketTypes.')) {
      const [_, field] = name.split('.');
      setNewEvent(prev => ({
        ...prev,
        ticketTypes: prev.ticketTypes.map((ticket, i) => 
          i === index ? { ...ticket, [field]: value } : ticket
        )
      }));
    } else {
      setNewEvent(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const addTicketType = () => {
    setNewEvent({
      ...newEvent,
      ticketTypes: [...newEvent.ticketTypes, { title: '', description: '', price: '', quantity: 0, color: '#FFFFFF', reservedTickets: 0, badge: '' }]
    });
  };

  const confirmDeletion = useCallback((itemType) => {
    const confirmationPhrase = 'I am sure';
    let warningMessage = `Warning: Deleting this ${itemType} cannot be undone.`;
    

      warningMessage += ` Deleting ${itemType} can be damaging to the business and users. ` +
        'It may result in loss of revenue, user trust, and potential legal issues. Please ask Chris for confirmation.';

    
    warningMessage += `\n\nIf you understand the consequences and still want to proceed, ` +
      `type "${confirmationPhrase}" to confirm deletion:`;
  
    const userInput = prompt(warningMessage);
    return userInput === confirmationPhrase;
  }, []);

  const removeTicketType = (index) => {
    if (confirmDeletion('ticket type')) {
      const updatedTicketTypes = newEvent.ticketTypes.filter((_, i) => i !== index);
      setNewEvent({ ...newEvent, ticketTypes: updatedTicketTypes });
    }
  };

  const handleFileChange = useCallback(async (e) => {
    const files = Array.from(e.target.files);
    try {
      const processedFiles = await processMultipleImages(files);
      setEventImages(processedFiles);
      setPreviewImages(processedFiles.map(file => URL.createObjectURL(file)));
    } catch (error) {
      console.error('Error processing images:', error);
      setUploadError('Error processing images. Please try again.');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadProgress(0);
    setUploadError('');

    try {
      const formData = new FormData();
      Object.keys(newEvent).forEach(key => {
        if (key === 'ticketTypes') {
          formData.append(key, JSON.stringify(newEvent[key]));
        } else if (key === 'dateTime') {
          const date = new Date(newEvent[key]);
          formData.append(key, date.toISOString());
        } else if (key === 'referralCodes') {
          // Don't append referralCodes to formData
        } else {
          formData.append(key, newEvent[key]);
        }
      });

      // Ensure these fields are not empty
      if (!newEvent.priceRange || !newEvent.lastEntry || !newEvent.ageRestriction) {
        throw new Error('Price Range, Last Entry, and Age Restriction are required fields');
      }

      eventImages.forEach((image, index) => {
        formData.append('images', image);
      });

      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      };

      let response;
      if (editingEvent) {
        response = await api.updateEvent(editingEvent._id, formData, config);
      } else {
        response = await api.createEvent(formData, config);
      }

      // Update the events list
      setEvents(prevEvents => {
        const updatedEvents = prevEvents.map(event => 
          event._id === response.data._id ? response.data : 
          (response.data.isFeatured ? { ...event, isFeatured: false } : event)
        );
        if (!editingEvent) {
          updatedEvents.push(response.data);
        }
        return updatedEvents;
      });

      resetForm();
      setUploadProgress(0);
    } catch (error) {
      console.error('Error submitting event:', error);
      if (error.response && error.response.data) {
        setUploadError(error.response.data.message || 'An error occurred while submitting the event.');
      } else {
        setUploadError('An error occurred while submitting the event. Please try again.');
      }
    } finally {
      setUploadProgress(0);
    }
  };

  const handleEditEvent = (event) => {
    setEditingEvent(event);
    setNewEvent({
      ...event,
      dateTime: formatDateTimeForInput(event.dateTime),
      ticketSalesCutoff: event.ticketSalesCutoff ? formatDateTimeForInput(event.ticketSalesCutoff) : '',
      ticketTypes: event.ticketTypes.map(ticket => ({
        ...ticket,
        price: ticket.price.toString(),
        quantity: ticket.quantity.toString(),
        reservedTickets: ticket.sold.toString()
      }))
    });
    setPreviewImages(event.images);
    setEventImages([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const resetForm = () => {
    setEditingEvent(null);
    setNewEvent({
      title: '',
      description: '',
      shortDescription: '',
      dateTime: '',
      location: '',
      vendor: '',
      ticketTypes: [{ title: '', description: '', price: '', quantity: 0, reservedTickets: 0, badge: '' }],
      priceRange: '',
      lastEntry: '',
      ageRestriction: '',
      isFeatured: false,
      ticketSalesCutoff: '',
    });
    setEventImages([]);
    setPreviewImages([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const generateReferralCode = async (eventId) => {
    try {
      const code = uuidv4().slice(0, 8);
      const influencer = prompt("Enter influencer's name:");
      if (influencer) {
        const response = await api.createReferralCode(eventId, code, influencer);
        setEvents(prevEvents => prevEvents.map(event =>
          event._id === eventId
            ? { ...event, referralCodes: [...(event.referralCodes || []), response.data] }
            : event
        ));
      }
    } catch (error) {
      console.error('Error generating referral code:', error);
      if (error.response && error.response.status === 401) {
        alert('You are not authorized to create referral codes. Please log in again.');
        // Optionally, you can redirect to the login page here
      } else {
        alert('An error occurred while generating the referral code. Please try again.');
      }
    }
  };

  const handleDeleteReferralCode = async (eventId, referralId) => {
    if (confirmDeletion('referral code')) {
      try {
        await api.deleteReferralCode(eventId, referralId);
        // After successful deletion, update the events state
        setEvents(prevEvents => prevEvents.map(event => {
          if (event._id === eventId) {
            return {
              ...event,
              referralCodes: event.referralCodes.filter(code => code._id !== referralId)
            };
          }
          return event;
        }));
      } catch (error) {
        console.error('Error deleting referral code:', error);
        setUploadError('Failed to delete referral code. Please try again.');
      }
    }
  };

  const handleDeleteEvent = async (eventId) => {
    if (confirmDeletion('event')) {
      try {
        await api.deleteEvent(eventId);
        setEvents(events.filter(event => event._id !== eventId));
        setUploadError('');
      } catch (error) {
        console.error('Error deleting event:', error);
        setUploadError('Failed to delete event. Please try again.');
      }
    }
  };

  const handleUpdateEventStatuses = async () => {
    try {
      const response = await api.updateEventStatuses();
      alert(response.data.message);
      fetchEvents(); // Refresh the events list after updating statuses
    } catch (error) {
      console.error('Error updating event statuses:', error);
      alert('Failed to update event statuses. Please try again.');
    }
  };

  return (
    <div className="event-management">
      <h2>{editingEvent ? 'Edit Event' : 'Create New Event'}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          value={newEvent.title}
          onChange={handleInputChange}
          placeholder="Event Title"
          required
        />
        <textarea
          name="description"
          value={newEvent.description}
          onChange={handleInputChange}
          placeholder="Event Description"
          required
        />
        <textarea
          name="shortDescription"
          value={newEvent.shortDescription}
          onChange={handleInputChange}
          placeholder="Short Description (max 120 characters)"
          maxLength={120}
          required
        />
        <input
          type="datetime-local"
          name="dateTime"
          value={newEvent.dateTime}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="location"
          value={newEvent.location}
          onChange={handleInputChange}
          placeholder="Event Location"
          required
        />
        <input
          type="text"
          name="vendor"
          value={newEvent.vendor}
          onChange={handleInputChange}
          placeholder="Event Vendor"
          required
        />
        {newEvent.ticketTypes.map((ticketType, index) => (
          <div key={index} className="ticket-type">
            <input
              type="text"
              name={`ticketTypes.title`}
              value={ticketType.title}
              onChange={(e) => handleInputChange(e, index)}
              placeholder="Ticket Type Title"
              required
            />
            <input
              type="text"
              name={`ticketTypes.description`}
              value={ticketType.description}
              onChange={(e) => handleInputChange(e, index)}
              placeholder="Ticket Type Description"
              required
            />
            <input
              type="text"
              name={`ticketTypes.badge`}
              value={ticketType.badge}
              onChange={(e) => handleInputChange(e, index)}
              placeholder="Ticket Badge string"
              required
            />
            <input
              type="number"
              name={`ticketTypes.price`}
              value={ticketType.price}
              onChange={(e) => handleInputChange(e, index)}
              placeholder="Ticket Price"
              required
            />
            <input
              type="number"
              name={`ticketTypes.quantity`}
              value={ticketType.quantity}
              onChange={(e) => handleInputChange(e, index)}
              placeholder="Ticket Quantity"
              required
            />
            <input
              type="number"
              name={`ticketTypes.reservedTickets`}
              value={ticketType.reservedTickets}
              onChange={(e) => handleInputChange(e, index)}
              placeholder="Reserved Tickets"
              required
            />
            <button type="button" onClick={() => removeTicketType(index)}>Remove</button>
          </div>
        ))}
        <button type="button" onClick={addTicketType}>Add Ticket Type</button>
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <div className="image-preview">
          {previewImages.map((image, index) => (
            <img key={index} src={image} alt={`Preview ${index + 1}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
          ))}
        </div>
        {uploadProgress > 0 && <LoadingBar progress={uploadProgress} />}
        {uploadError && <p className="error-message">{uploadError}</p>}
        <input
          type="text"
          name="priceRange"
          value={newEvent.priceRange}
          onChange={handleInputChange}
          placeholder="Price Range"
          required
        />
        <input
          type="text"
          name="lastEntry"
          value={newEvent.lastEntry}
          onChange={handleInputChange}
          placeholder="Last Entry"
          required
        />
        <input
          type="text"
          name="ageRestriction"
          value={newEvent.ageRestriction}
          onChange={handleInputChange}
          placeholder="Age Restriction"
          required
        />
        <label className="checkbox-label">
            <input
              type="checkbox"
              name="isFeatured"
              checked={newEvent.isFeatured}
              onChange={handleInputChange}
            />
            Featured Event
          </label>
        <div className="form-group">
          <label htmlFor="ticketSalesCutoff">Ticket Sales Cutoff:</label>
          <input
            type="datetime-local"
            id="ticketSalesCutoff"
            name="ticketSalesCutoff"
            value={newEvent.ticketSalesCutoff}
            onChange={handleInputChange}
          />
        </div>
        <button type="submit">{editingEvent ? 'Update Event' : 'Create Event'}</button>
        {editingEvent && <button type="button" onClick={resetForm}>Cancel Edit</button>}
      </form>
      <div className="events-list">
        <h3>Existing Events</h3>
        <button onClick={handleUpdateEventStatuses}>Update Event Statuses</button>
        {isLoading ? (
          <p>Loading events...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : events.length > 0 ? (
          events.map(event => (
            <div key={event._id} className={`event-item ${event.isFeatured ? 'featured' : ''}`}>
              <h4>{event.title}</h4>
              {event.isFeatured && <span className="featured-label">Featured</span>}
              <ReactMarkdown
                rehypePlugins={[[rehypeSanitize, customSchema]]}
                components={{
                  h1: ({ node, ...props }) => <h1 className="event-description-h1" {...props} />,
                  h2: ({ node, ...props }) => <h2 className="event-description-h2" {...props} />,
                  h3: ({ node, ...props }) => <h3 className="event-description-h3" {...props} />,
                  p: ({ node, ...props }) => <p className="event-description-p" {...props} />,
                  strong: ({ node, ...props }) => <strong className="event-description-strong" {...props} />,
                  em: ({ node, ...props }) => <em className="event-description-em" {...props} />,
                  a: ({ node, ...props }) => <a className="event-description-link" target="_blank" rel="noopener noreferrer" {...props} />,
                  ul: ({ node, ...props }) => <ul className="event-description-ul" {...props} />,
                  ol: ({ node, ...props }) => <ol className="event-description-ol" {...props} />,
                  li: ({ node, ...props }) => <li className="event-description-li" {...props} />,
                  hr: ({ node, ...props }) => <hr className="event-description-hr" {...props} />,
                }}
              >
                {event.description}
              </ReactMarkdown>
              <p>Date: {new Date(event.dateTime).toLocaleString()}</p>
              <p>Location: {event.location}</p>
              <p>Vendor: {event.vendor}</p>
              <p>Price Range: {event.priceRange}</p>
              <p>Last Entry: {event.lastEntry}</p>
              <p>Age Restriction: {event.ageRestriction}</p>
              <p>Featured: {event.isFeatured ? 'Yes' : 'No'}</p>
              {event.ticketSalesCutoff && (
                <p>Ticket sales end on: {new Date(event.ticketSalesCutoff).toLocaleString()}</p>
              )}
              <div className="ticket-types-container">
                <h5 className="ticket-types-header">Ticket Types:</h5>
                <ul className="ticket-type-list">
                  {event.ticketTypes.map((ticketType, index) => (
                    <li key={index} className="ticket-type-item">
                      <div className="ticket-type-title">{ticketType.title}</div>
                      <div className="ticket-type-price">£{ticketType.price}</div>
                      <div className="ticket-type-counts">
                        <span className="ticket-type-count reserved">
                          Currently Reserved: {ticketType.sold}
                        </span>
                        <span className="ticket-type-count total">
                          Total Available: {ticketType.quantity}
                        </span>
                        <span className="ticket-type-count created">
                          Created: {ticketCounts[event._id]?.ticketTypes.find(tt => tt._id === ticketType._id.toString())?.total || '0'}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="event-total-counts">
                <span className="event-total-count">
                  Total Created: {ticketCounts[event._id]?.totalCount || '0'}
                </span>
                <span className="event-total-count">
                  Total Scanned: {ticketCounts[event._id]?.totalScanned || '0'}
                </span>
              </div>
              <h5>Images:</h5>
              <div className="event-images">
                {event.images && event.images.map((image, index) => (
                  <img key={index} src={image} alt={`Event ${index + 1}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                ))}
              </div>
              <button onClick={() => handleEditEvent(event)}>Edit</button>
              <button onClick={() => handleDeleteEvent(event._id)}>Delete</button>
              <button onClick={() => generateReferralCode(event._id)}>Generate Referral Code</button>
              <button onClick={() => setShowFinancials(prev => ({ ...prev, [event._id]: !prev[event._id] }))}>
                {showFinancials[event._id] ? 'Hide Financials' : 'Show Financials'}
              </button>
              {showFinancials[event._id] && <EventFinancials eventId={event._id} />}
              <h5>Referral Codes:</h5>
              <ul>
                {event.referralCodes && event.referralCodes.length > 0 ? (
                  event.referralCodes.map((code) => (
                    <li key={code._id}>
                      {code.influencer}: {code.code} (Clicks: {code.clicks}, Orders: {code.orders ? code.orders.length : 0})
                      <button onClick={() => handleDeleteReferralCode(event._id, code._id)}>Delete</button>
                    </li>
                  ))
                ) : (
                  <li>No referral codes yet</li>
                )}
              </ul>
            </div>
          ))
        ) : (
          <p>No events found. Create a new event to get started.</p>
        )}
      </div>
    </div>
  );
};

export default EventManagement;